import React from "react";

import Container from "@material-ui/core/Container";

import { ReactComponent as RightWave } from "../../assets/svg/rightWave.svg";
import Carousel from "../carousel";
import CardStructure from "../cardStructure";

import StructureAndServicesTitle from "../../assets/img/estruturasServicos.png";

import CafeDaManha from "../../assets/img/cafeDaManha.png";
import Piscina from '../../assets/img/areas-comuns/piscina-2.jpg';
import Portaria from '../../assets/img/Portaria.jpeg';
import Wifi from '../../assets/img/areas-comuns/recepcao.jpg';
import Passeio from '../../assets/img/passeios.jpg';
import Translado from '../../assets/img/translado.jpg'

const subtitle =
  "Aproveite os serviços que a Pousada disponibiliza para seus hóspedes.";

export default class StructureAndServices extends React.Component {
  render() {
    const desktop = this.props.desktop;
    const CONTEUDO = [
      {
        src: CafeDaManha,
        title: "Café da Manhã",
        subtitle: "Aproveite o café da manhã servido sempre no início do seu dia.",
        border: desktop
      },
      {
        src: Piscina,
        title: "Piscina",
        subtitle: "Relaxe e aproveite bem o seu dia.",
        border: desktop
      },
      {
        src: Wifi,
        title: "Wi-Fi",
        subtitle: "Internet disponível a todos os hóspedes.",
        border: desktop
      },
      {
        src: Portaria,
        title: "Portaria 24hrs",
        subtitle: "Aproveite a cidade e volte com segurança.",
        border: desktop
      },
      {
        src: Passeio,
        title: "Passeios",
        subtitle: "Conheça os melhores passeios em Porto de Galinhas.",
        border: desktop
      },
    ];
    return (
      <>
        <Container
          id="section__structure"
          className="structureAndServices__container"
          style={{overflow:'hidden'}}
        >

          {desktop &&
            <div className="rightWaveContainer">
              <RightWave width={150} className="wave" />
            </div>
          }
          <img
            src={StructureAndServicesTitle}
            className="StructureAndServicesLogo"
            height={120}
            alt="Estrutura e Serviços"
          />
          <p className="struct__subtitle">{subtitle}</p>
          <Carousel
            component={CardStructure}
            paginationNumber={desktop? 3:1}
            componentData={CONTEUDO}
            orientation={"horizontal"}
          />
        </Container>
      </>
    );
  }
}
