import React from "react";

import Container from "@material-ui/core/Container";

import BannerLogo from "../../assets/img/bannerLogo.png";

import { ReactComponent as LeftWave } from "../../assets/svg/leftWave.svg";
import { ReactComponent as RightWave } from "../../assets/svg/rightWave.svg";
import { ReactComponent as MouseCall } from "../../assets/svg/mouseCall.svg";

import FarolDoPortoFont from "../../assets/img/farolDoPortoFont.png";


const subtitle = "Sua pousada em Porto de Galinhas";
const textScroll = "Conheça nossa pousada";
const bookingText = "Faça já sua reserva";
const textInfo1 = "A Pousada fica a cerca de 10 minutos de caminhada das Piscinas Naturais de Porto de Galinhas. Fica perto de bares, restaurantes e lojas.";
const textInfo2 = "Sua localização também conta com comodidades próximas como farmácias, supermercados, locadoras de veículos e um posto de combustíveis.";
const textInfo3 = "Em atenção ao Decreto Estadual n. 49.668, de 30 de outubro de 2020, como medidas para o enfrentamento da emergência de saúde pública decorrente do novo coronavírus, é obrigatório o uso de máscaras nas áreas comuns da pousada, bem como é proibido o consumo de comidas e bebidas em tais áreas.";
const textInfo4 = "Atenção! Informamos que qualquer cancelamento de reserva só será possível com 8 dias de antecedência.";

const BannerDesktop = () => {

  return (
    <>
      <Container id="section__banner" style={{overflow:'hidden'}} className="container">
        <div className="root">
          <div className="overlay" />
          <div className="rightBall" />
          <div className="leftBall" />
          <div className="leftWaveContainer">
            <LeftWave width={150} className="wave" fill="transparent" />
          </div>
          <div className="tittle">
            <img alt="Logo do Farol do Porto" src={BannerLogo} className="bannerLogo" />
            <p className="subTitle">{subtitle}</p>
          </div>
          <div className="rightWaveContainer">
            <RightWave width={150} className="wave" />
          </div>
          <div className="callToScroll">
            <MouseCall height={55} />
            <p className="callToScrollText">{textScroll}</p>
          </div>
        </div>
      </Container>
      <Container id="section__infoBanner" className="baseInfoBanner">
        <Container className="infoBannerContainer">
          <img src={FarolDoPortoFont} alt="Farol do Porto" height={75} />
          <p className="text">{textInfo1}</p>
          <p className="text">{textInfo2}</p>
          <p className="text">{textInfo3}</p>
          <p className="text">{textInfo4}</p>
          <div className="callToBookingWrap">
            <a className="callToBooking waves-effect" rel="noopener noreferrer" href="https://reservasonline.lexsis.com.br/ReservasHTML5-1.0.0/?idh=1057" target="_blank">
              <p>{bookingText}</p>
            </a>
          </div>
        </Container>
        <Container className="bannerImage">
          <div className="overlay" />
        </Container>
      </Container>
    </>
  );
};

export default BannerDesktop;
