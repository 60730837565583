import React from "react";

import { useHistory } from "react-router-dom";

import Error404Logo from "../assets/img/404_image_error.png";
import Error404LogoText from "../assets/img/404_text_error.png";
import Error404Title from "../assets/img/404_title.png";
import Error404WaveLeft from "../assets/svg/404_wave_left.svg";
import Error404WaveRight from "../assets/svg/404_wave_right.svg";

const ErrorScreen = () => {
  let history = useHistory();

  const goToHome = () => history.push("/");

  return (
    <div className="errorContainer">
      <div className="errorRightSide">
        <img alt="Onda para estilizar a tela a direita" src={Error404WaveRight} />
        <div></div>
      </div>
      <div className="errorLeftSide">
        <img alt="Onda para estilizar a tela a esquerda" src={Error404WaveLeft} />
      </div>
      <div className="errorImageContainer">
        <img alt="Texto representado Erro do texto completo Erro 404" className="firstChild" src={Error404LogoText} />
        <img alt="Texto representado 404 do texto completo Erro 404" className="secondChild" src={Error404Logo} />
      </div>
      <div className="errorTitleContainer">
        <img alt="Texto dizendo que não conseguimos acessar essa página" src={Error404Title} />
        <button className="errorButton" onClick={goToHome}>
          VOLTAR AO INÍCIO
        </button>
      </div>
    </div>
  );
};

export default ErrorScreen;
