import React, { useState } from "react";

import Container from "@material-ui/core/Container";
import ContactDrawer from "../contact";
import LogoFooter from "../../assets/img/logo_footer.png";
import FooterWavebox from "../../assets/img/wavebox_footer.png";
import SagueeLogo from "../../assets/img/logo_saguee.png";
import { ReactComponent as IconWhatsapp } from "../../assets/svg/iconWhatsapp.svg";
import { ReactComponent as IconInstagram } from "../../assets/svg/iconInstagram.svg";
import { ReactComponent as IconFacebook } from "../../assets/svg/iconFacebook.svg";

import { scroller } from "react-scroll";

const items = [
  {
    title: "INÍCIO",
    section: "section__banner",
  },
  {
    title: "A POUSADA",
    section: "section__infoBanner",
  },
  {
    title: "ESTRUTURA E SERVIÇOS",
    section: "section__structure",
  },
  {
    title: "GALERIA",
    section: "section__galery",
  },
  {
    title: "DEPOIMENTOS",
    section: "section__depoimentos",
  },
  {
    title: "COMO CHEGAR",
    section: "section__comochegar",
  },
  {
    title: "CONTATO",
  },
];

const Footer = () => {
  const [contactDrawerState, setContactDrawerState] = useState(false);

  const handleOnClickContact = () => {
    setContactDrawerState(true);
  };

  const contactDrawerClose = () => setContactDrawerState(false);

  const renderItems = () =>
    items.map((item) => {
      let handler = () => {};
      switch (item.title) {
        case "CONTATO":
          handler = () => handleOnClickContact(true);
          break;
        default:
          handler = ((id) => () => scrollTo(id))(item.section);
      }
      return (
        <p key={item.title} onClick={handler} className="footerSections">
          {item.title}
        </p>
      );
    });

  const scrollTo = (id) => {
    scroller.scrollTo(id, {
      offset: -90,
      duration: 1500,
      delay: 100,
      smooth: true,
    });
  };

  const goToWhatsapp = () =>
    window.open("https://api.whatsapp.com/send?l=pt_BR&phone=558196346282");

  const goToFacebook = () =>
    window.open("https://www.facebook.com/faroldoportopousada/");

  const goToInstagram = () =>
    window.open("https://www.instagram.com/faroldoportopousada/");

  return (
    <>
      <div className="footerContainer">
        <Container className="footerCtnerLeft">
          <div className="footerSectionsBar" />
          {renderItems()}
        </Container>

        <Container className="footerLogoContainer">
          <img alt="Logo do Farol do Porto" src={LogoFooter} />
        </Container>

        <Container className="footerFarolInfo">
          <h1>ONDE NOS ACHAR</h1>
          <p>
            Av da Assembleia, 109 <br /> Porto de Galinhas, Ipojuca, PE
            55592-000, Brasil
          </p>
          <h2>+55 (81) 3552-1616</h2>
          <h2>faleconosco@faroldoporto.com.br</h2>
          <div className="footerIconContainer">
            <IconWhatsapp
              onClick={goToWhatsapp}
              className="footerBottomIcon"
              width={30}
              height={30}
            />
            <IconFacebook
              onClick={goToFacebook}
              className="footerBottomIcon"
              width={30}
              height={30}
            />
            <IconInstagram
              onClick={goToInstagram}
              className="footerBottomIcon"
              width={30}
              height={30}
            />
          </div>
        </Container>

        <div className="sagueeFooterMark">
          <div className="sagueeFooterLogoContainer">
            <h1>Made by </h1>
            <img alt="Logo da Saguee" src={SagueeLogo}></img>
          </div>
          <p>&copy; Copyright 2020 Farol do Porto</p>
          <img alt="Onda Branca ao redor da Logo da Saguee" className="sagueeFooterWavebox" src={FooterWavebox} />
        </div>
      </div>

      <ContactDrawer
        drawerState={contactDrawerState}
        closeDrawer={contactDrawerClose}
      />
    </>
  );
};

export default Footer;
