import React from 'react'
/* Guia de usos do carrosel:
(Um exemplo está presente no componente "TelaDepoimentos")
Você deve passar como props para o componente 3 argumentos:
- component: Os componentes que serão renderizados nesse carrossel (Um card de depoimento, etc.).
- paginationNumber: O número de componentes por página para a paginação
- componentData: O conteúdo que deverá ser passado para dentro desse componente (No caso de um card de depoimento, o depoimento, quem escreveu e a fonte, por exemplo).
*/


export default class Carousel extends React.Component{
  constructor(props){
    super(props);
    this.state={
      currentPage:1,
      gatilho:true,
    }
  }


  backPage = () =>{
    if(this.state.currentPage !== 1){
      this.setState({gatilho:false})
      setTimeout(()=>{this.setState({currentPage:this.state.currentPage-1, gatilho:true})}, 1000)
    }else{
      this.setState({gatilho:false})
      setTimeout(()=>{
      this.setState({
        currentPage:Math.ceil(this.props.componentData.length / this.props.paginationNumber), gatilho:true
      })},1000)
    }
  }

  nextPage = () => {
      if(this.state.currentPage !== Math.ceil(this.props.componentData.length / this.props.paginationNumber)){ // mudar para ser dinamico
        this.setState({gatilho:false})
        setTimeout(()=>{this.setState({currentPage:this.state.currentPage+1, gatilho:true})}, 1000)
        
      }else{
        this.setState({gatilho:false})
        setTimeout(()=>{this.setState({currentPage:1, gatilho:true})}, 1000)
      }
  }

  renderingComponent = (Component, params, key) => {
    if(key % 2 === 0)
    return <Component chave="even" number={this.props.paginationNumber} animateTrigger={this.state.gatilho} key={key} componentArguments={params}/>
    else
    return <Component chave="odd" number={this.props.paginationNumber} animateTrigger={this.state.gatilho} key={key} componentArguments={params}/>
  }

  render () {

    let MAX_PAGE = Math.ceil(this.props.componentData.length / this.props.paginationNumber)
    let calc = 100 / MAX_PAGE * this.state.currentPage;
    let componentTest = this.props.component;

    return(
      <div>
        <div className={this.props.orientation === "horizontal"? "horizontalCarousel" : "verticalCarousel"}>
          {this.props.componentData.slice( (this.state.currentPage-1)*this.props.paginationNumber, this.state.currentPage*this.props.paginationNumber ).map((item, i) => (
            this.renderingComponent(componentTest, item, i)
          ))}
        </div>

        <div className="carouselMenu">
          <p onClick={this.backPage}> &lt; </p>
          <p onClick={this.nextPage}> &gt; </p>
        </div>

        <div className="carouselWrapper"> 
          <div style={this.props.wrapper==="depoimentos"?{width:'40%'}:this.props.wrapper==="galeria"?{width:'70%'}:null} className="carouselMenuBackBar">
            <div className="carouselMenuFrontBar"  style={{width:`${calc}%`}}/>
          </div>
        </div>

      </div>
    )
  }
}