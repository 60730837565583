import React from 'react'
import {Animated} from "react-animated-css";


const cardDepoimento = (props) => {
  return(
     
      <Animated style={{width:'40%'}} animationIn={props.chave==='odd'?"slideInRight":"slideInLeft"} animationOut={props.chave==='odd'?"slideOutRight":"slideOutLeft"} animationInDuration={1000} animationOutDuration={1000} isVisible={props.animateTrigger} >
        <div className={props.chave!=='odd'?"cardDepoimentoCarousel cardDepoimentoMLA":"cardDepoimentoCarousel cardDepoimentoMRA"} style={{width:'80%', height:'90%'}}>
          <h1 onClick={()=>{}}>{props.componentArguments.depo}</h1>
          <p style={{display:'flex', alignItems:'center'}}><span className="cardDepoimentoNome">{props.componentArguments.nome}</span><span className="cardDepoimentoFonte">|</span><span className="cardDepoimentoFonte"> via {props.componentArguments.fonte}</span></p>
        </div>
      </Animated>
  )
}

export default cardDepoimento