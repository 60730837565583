import React from 'react';

import { ReactComponent as IconWhatsapp } from "../../assets/svg/iconWhatsappSecondColor.svg";
import { ReactComponent as IconInstagram } from "../../assets/svg/iconInstagramSecondColor.svg";
import { ReactComponent as IconFacebook } from "../../assets/svg/iconFacebookSecondColor.svg";

const SocialMediaDesktop = (props) => {
  const goToWhatsapp = () =>
    window.open("https://api.whatsapp.com/send?l=pt_BR&phone=558196346282");

  const goToFacebook = () =>
    window.open("https://www.facebook.com/faroldoportopousada/");

  const goToInstagram = () =>
    window.open("https://www.instagram.com/faroldoportopousada/");

  return (
    <div className="socialMediaCard">
      <IconFacebook onClick={goToFacebook} className="iconSocialMediaCard" />
      <IconInstagram onClick={goToInstagram} className="iconSocialMediaCard" />
      <IconWhatsapp onClick={goToWhatsapp} className="iconSocialMediaCard" />
    </div>
  )
}

export default SocialMediaDesktop;