import React from "react";

import GaleriaLogo from "../../assets/img/galerialogo.png";

import GaleriaFotos from "../galeriaFotos";
import GaleriaFotosWeb from '../galeriaFotos/fotoGaleriaWeb'
import Carousel from "../carousel";

import { ReactComponent as LeftWave } from "../../assets/svg/leftWave.svg";

import AREA_COMUM_1 from '../../assets/img/areas-comuns/fachada.jpg';
import AREA_COMUM_2 from '../../assets/img/areas-comuns/cafe-manha.jpg';
import AREA_COMUM_3 from '../../assets/img/areas-comuns/cafe-manha-2.jpg';
import AREA_COMUM_4 from '../../assets/img/areas-comuns/piscina.jpg';
import AREA_COMUM_5 from '../../assets/img/areas-comuns/piscina-2.jpg';
import AREA_COMUM_6 from '../../assets/img/areas-comuns/recepcao.jpg';
import AREA_COMUM_7 from '../../assets/img/areas-comuns/lateral.jpg';

import QUARTO_1 from '../../assets/img/quartos/quarto-duplo.jpg';
import QUARTO_2 from '../../assets/img/quartos/quarto-duplo-2.jpg';
import QUARTO_3 from '../../assets/img/quartos/quarto-triplo.jpg';
import QUARTO_4 from '../../assets/img/quartos/quarto-triplo-2.jpg';
import QUARTO_5 from '../../assets/img/quartos/quarto-quadruplo.jpg';
import QUARTO_6 from '../../assets/img/quartos/quarto-quadruplo-2.jpg';
import QUARTO_7 from '../../assets/img/quartos/lateral-quartos.jpg';

const FOTOS = [
  {
    uri: AREA_COMUM_1,
    desc: "Fachada da pousada",
    tag: "area_comum",
    alt: "Alt Exemplo"
  },
  {
    uri: AREA_COMUM_2,
    desc: "Área do café da manhã",
    tag: "area_comum",
    alt: "Alt Exemplo"
  },
  {
    uri: AREA_COMUM_4,
    desc: "Área da piscina",
    tag: "area_comum",
    alt: "Alt Exemplo"
  },
  {
    uri: AREA_COMUM_5,
    desc: "Área da piscina",
    tag: "area_comum",
    alt: "Alt Exemplo"
  },
  {
    uri: AREA_COMUM_6,
    desc: "Recepção",
    tag: "area_comum",
    alt: "Alt Exemplo"
  },
  { 
    uri:AREA_COMUM_7,
    desc: "Lateral",
    tag: "area_comum",
    alt: "Alt Exemplo"
  },
  {
    uri: QUARTO_1,
    desc: "Quarto duplo",
    tag: "quartos",
    alt: "Alt Exemplo"
  },
  {
    uri: QUARTO_2,
    desc: "Quarto duplo",
    tag: "quartos",
    alt: "Alt Exemplo"
  },
  {
    uri: QUARTO_3,
    desc: "Quarto triplo",
    tag: "quartos",
    alt: "Alt Exemplo"
  },
  {
    uri: QUARTO_4,
    desc: "Quarto triplo",
    tag: "quartos",
    alt: "Alt Exemplo"
  },
  {
    uri: QUARTO_5,
    desc: "Quarto quádruplo",
    tag: "quartos",
    alt: "Alt Exemplo"
  },
  {
    uri: QUARTO_6,
    desc: "Quarto quádruplo",
    tag: "quartos",
    alt: "Alt Exemplo"
  },

];

export default class Galeria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tagAtiva: "quartos",
      componentData: [],
    };
  }

  filtroTag = (item) => {
    return item.tag === this.state.tagAtiva;
  };

  render() {
    let componentData = FOTOS.filter(this.filtroTag).map((item, i, arr) => {
      if (i > 0 && i < arr.length - 1) {
        item["prevUri"] = arr[i - 1].uri;
        item["nextUri"] = arr[i + 1].uri;
      } else if (i === 0) {
        item["prevUri"] = null;
        item["nextUri"] = arr[i + 1].uri;
      } else {
        item["prevUri"] = arr[i - 1].uri;
        item["nextUri"] = null;
      }
      return item;
    });

    return (
      <div>
        <div id="section__galery" className="galeriaGreenContainer">
          <div className="galeriaLogo">
            <img alt="Imagem com o texto: Galeria" src={GaleriaLogo} />
          </div>

          <h2>Veja um pouco mais da nossa pousada!</h2>

          <div className="galeriaWhiteWave">
            <LeftWave width={150} className="wave" />
          </div>

          <div className="galeriaTagButtons">
            <button
              onClick={() => this.setState({ tagAtiva: "quartos" })}
              className={this.state.tagAtiva === "quartos" ? "tagAtiva" : ""}
            >
              Quartos
            </button>
            {/* <button
              onClick={() => this.setState({ tagAtiva: "lazer" })}
              className={this.state.tagAtiva === "lazer" ? "tagAtiva" : ""}
            >
              Lazer
            </button> */}
            <button
              onClick={() => this.setState({ tagAtiva: "area_comum" })}
              className={this.state.tagAtiva === "area_comum" ? "tagAtiva" : ""}
            >
              Áreas Comuns
            </button>
            {/* <button
              onClick={() => this.setState({ tagAtiva: "arredor" })}
              className={this.state.tagAtiva === "arredor" ? "tagAtiva" : ""}
            >
              Redondezas
            </button> */}
          </div>
        </div>

        <div className="galeriaWhiteContainer">
          <Carousel
            component={this.props.web? GaleriaFotosWeb : GaleriaFotos}
            paginationNumber={this.props.web? 3: 1}
            componentData={componentData}
            orientation={this.props.web?'horizontal':'vertical'}
            wrapper={this.props.web?"galeria":null}
          />
        </div>
      </div>
    );
  }
}
