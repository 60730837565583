import React from "react";
import "./sheets/main.scss";

import HomeScreen from "./views/HomeScreen";
import ErrorScreen from "./views/ErrorScreen";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import * as ROUTES from "./constants/Routes";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path={ROUTES.HOME_SCREEN} component={HomeScreen} />
        <Route path={ROUTES.ERROR_SCREEN} component={ErrorScreen} />
      </Switch>
    </Router>
  );
}

export default App;
