import React from 'react'


import {Animated} from "react-animated-css";

import DescrTil from '../../assets/img/galeriatil.png'

const limitatorCenter={
  maxHeight: '500px',
  width: '80%',
  margin: 'auto'
}

const limitatorLeft={
  maxHeight: '500px',
  width: '80%',
  marginLeft: 'auto'
}

const limitatorRight={
  maxHeight: '500px',
  width: '80%',
  marginRight: 'auto',
}

export default class GaleriaFotos extends React.Component{

  render(){
    return(
      <div className="galeriaImageComponent">
        {this.props.componentArguments.prevUri != null &&
          <div className="galeriaPrevImg galeriaItem">
            <Animated animationIn={"fadeIn"} animationOut={"fadeOut"} animationInDuration={1000} animationOutDuration={1000} isVisible={this.props.animateTrigger}>
            <img style={limitatorLeft} alt="Imagem com o texto: Galeria" className="galeriaIMG" src={this.props.componentArguments.prevUri}/>
            </Animated>
          </div>
        }

          <div className="galeriaCurrentImg galeriaItem">
          <Animated animationIn={"fadeIn"} animationOut={"fadeOut"} animationInDuration={1000} animationOutDuration={1000} isVisible={this.props.animateTrigger}>
            <img  style={limitatorCenter} alt="Imagem com o texto: Galeria" className="galeriaIMG" src={this.props.componentArguments.uri}/>
            <div style={{marginTop:'15px', width:'100%', display:'flex', alignItems:'center'}}>
              <img alt={this.props.componentArguments.alt} src={DescrTil}></img>
              <h2>{this.props.componentArguments.desc}</h2>
            </div>
          </Animated>
          </div>

        {this.props.componentArguments.nextUri != null && 
          <div className="galeriaNextImg galeriaItem">
            <Animated animationIn={"fadeIn"} animationOut={"fadeOut"} animationInDuration={1000} animationOutDuration={1000} isVisible={this.props.animateTrigger}>
            <img  style={limitatorRight} alt="Imagem com o texto: Galeria" className="galeriaIMG" src={this.props.componentArguments.nextUri}/>
            </Animated>
          </div>
        }
      </div>
    )
  }
}