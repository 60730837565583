import React from 'react'
export default class CardStructure extends React.Component {
  render() {

    const reactiveStyle = {
      backgroundImage: `url(${this.props.componentArguments.src})`,
      marginRight: this.props.componentArguments.border? '10px':0,
      marginLeft:this.props.componentArguments.border? '10px':0,
    }

    return(
      <div className="cardStructureCarousel" style={reactiveStyle}>
        <div className="overlay"/>
        <div className="cardDisplay">
          <p className="cardTitle">{this.props.componentArguments.title}</p>
          <p className="cardSubtitle">{this.props.componentArguments.subtitle}</p>
        </div>
      </div>
    )
  }
}