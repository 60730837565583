import React from 'react'


import {Animated} from "react-animated-css";

import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';

import DescrTil from '../../assets/img/galeriatil.png';

const imageStyle={
  width:'300px',
  height:'300px',
}

export default class GaleriaFotos extends React.Component{
  constructor(props){
    super(props)
    this.state={
      zoomImg:false,
    }
  }

  closeModal = () => {
    this.setState({zoomImg:false})
  }

  render(){
    return(
      <div className="galeriaImageComponentWeb">

          <div onClick={()=>this.setState({zoomImg:true})} style={{width:`calc(100/${this.props.number})`, cursor:'pointer'}} className="galeriaCurrentImg galeriaItem">
          <Animated animationIn={"fadeIn"} animationOut={"fadeOut"} animationInDuration={1000} animationOutDuration={1000} isVisible={this.props.animateTrigger}>
            <img alt="Imagem com o texto: Galeria" style={imageStyle} className="galeriaIMG" src={this.props.componentArguments.uri}/>
            <div style={{marginTop:'15px', width:'100%', display:'flex', alignItems:'center'}}>
              <img  alt="Texto: Veja um pouco mais da nossa pousada" src={DescrTil}></img>
              <h2>{this.props.componentArguments.desc}</h2>
            </div>
          </Animated>
          </div>


          {true &&
            <Modal 
            open={this.state.zoomImg}
            onClose={this.closeModal}
            >
              <Fade in={this.state.zoomImg}>
                <img alt={this.props.componentArguments.alt} className="galeriaFotoZoomIn" style={{position:'fixed', width:'40vw', height:'40vw', left:'50%', top:'50%', marginLeft:'-20vw', marginTop:'-20vw', outline:'0'}} src={this.props.componentArguments.uri}/>
              </Fade>
            </Modal>
          }

      </div>
    )
  }
}