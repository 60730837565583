import React from "react";

import { ReactComponent as LeftWave } from "../../assets/svg/leftWave.svg";

import Carousel from "../carousel";
import CardDepoimento from "../cardDepoimento";
import CardDepoimentoWeb from '../cardDepoimento/cardDepoWeb'
import DepoimentosLogo from "../../assets/img/depoimentoslogo.png";

const DEPOIMENTOS = [
  {
    depo:
      "Pousada bem localizada, fica perto do Centro de Artesanato e da praia de Porto de Galinhas, uma caminhadinha de uns 10 min... A estrutura é boa, o quarto com camas confortáveis, roupa de cama e banho limpas, ar condicionado... o café da manhã é deliciosos com sucos das frutas da região. Um charme!",
    nome: "Paula",
    fonte: "TripAdvisor",
    key: 1,
  },
  {
    depo:
      "A minha hospedagem eu nao tive do que reclamar, funcionários simpáticos, atenciosos, café da manhã simples, porem delicioso. Esta de parabéns por tudo. Nada há reclamar. Indicarei sempre e retornarei mais vezes. A pousada combina com a cidade: perfeita.",
    nome: "Yuri",
    fonte: "TripAdvisor",
    key: 2,
  },
  {
    depo:
      "Muito bom, voltarei outras vezes. Café da manhã ótimo, quarto muito organizado e funcionários super atenciosos.",
    nome: "Renata",
    fonte: "Booking",
    key: 3,
  },
  {
    depo:
      "Os quartos sempre limpos. No café da manhã podíamos pedir tapioca que faziam na hora. Em cada quarto tinha um pequeno varal na parte de fora, isso ajudou muito.",
    nome: "Glaucia",
    fonte: "Booking",
    key: 4,
  },
  {
    depo:
      "Adorei, foi muito bem recebida pelos funcionários e no decorrer da estadia também. Muito obrigada a toda equipe pela excelência em atendimento.",
    nome: "Marily",
    fonte: "Trivago",
    key: 5,
  },
  {
    depo:
      "Atendimento e atenção dos funcionários para com os hospedes muito boa, sempre atenciosos. Perto de tudo é no centro sem ser muito perto do \"furdunço\".",
    nome: "Margareth",
    fonte: "Trivago",
    key: 6,
  },
  {
    depo:
      "Gostamos muito da localização, para o centro de porto de galinhas da pra ir a pé, local super tranquilo a qualquer hora do dia. Assim dispensamos o carro que tinhamos alugado. Funcionários super prestativos e atenciosos.O quarto e um pouco pequeno, mas na relação de custo x beneficio se enquadra bem.",
    nome: "Valdemir",
    fonte: "Decolar",
    key: 7,
  },
  {
    depo:
      "Friendly staff e bom café da manhã. perto do centro. meninas as instalações, mas é bom porque é um destino para estar fora todo o dia e voltar a dormir apenas.",
    nome: "Julieta",
    fonte: "Decolar",
    key: 8,
  }
];

export default class TelaDepoimentos extends React.Component {
  render() {
    return (
      <div id="section__depoimentos" className="depoimentosContainer">
        <div className="depoimentosGreenWave">
          <LeftWave width={150} className="wave" fill="transparent" />
        </div>
        <div className="depoimentosLogo">
          <img alt="Imagem com o texto: Depoimentos" src={DepoimentosLogo} />
        </div>
        <Carousel
          component={this.props.web? CardDepoimentoWeb : CardDepoimento}
          paginationNumber={this.props.web? 4 : 5}
          componentData={DEPOIMENTOS}
          wrapper={this.props.web?"depoimentos":null}
          orientation={this.props.web? 'horizontal' : 'vertical'}
        />
      </div>
    );
  }
}
