import React from 'react'
import {Animated} from "react-animated-css";


const cardDepoimento = (props) => {
  return(
     
      <Animated animationIn={props.chave==='odd'?"slideInLeft":"slideInRight"} animationOut={props.chave==='odd'?"slideOutRight":"slideOutLeft"} animationInDuration={1000} animationOutDuration={1000} isVisible={props.animateTrigger} >
        <div className="cardDepoimentoCarousel">
          <h1 onClick={()=>{}}>{props.componentArguments.depo}</h1>
          <p><span className="cardDepoimentoNome">{props.componentArguments.nome}</span><span className="cardDepoimentoFonte">|</span><span className="cardDepoimentoFonte"> via {props.componentArguments.fonte}</span></p>
        </div>
      </Animated>
  )
}

export default cardDepoimento