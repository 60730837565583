import React from "react";

import Container from "@material-ui/core/Container";

import FarolDoPortoFont from "../../assets/img/farolDoPortoFont.png";
import LogoWithoutText from "../../assets/img/logoWithoutText.png";

import "./InfoBanner.css";
const textPart1 = "A Pousada fica a cerca de 10 minutos de caminhada das Piscinas Naturais de Porto de Galinhas. Fica perto de bares, restaurantes e lojas.";
const textPart2 = "Sua localização também conta com comodidades próximas como farmácias, supermercados, locadoras de veículos e um posto de combustíveis.";
const textPart3 = "Em atenção ao Decreto Estadual n. 49.668, de 30 de outubro de 2020, como medidas para o enfrentamento da emergência de saúde pública decorrente do novo coronavírus, é obrigatório o uso de máscaras nas áreas comuns da pousada, bem como é proibido o consumo de comidas e bebidas em tais áreas.";
const textPart4 = "Atenção! Informamos que qualquer cancelamento de reserva só será possível com 8 dias de antecedência.";


const InfoBanner = () => {
  return (
    <>
      <Container id="section__infoBanner" className="infoBannerContainer">
        <img
          src={LogoWithoutText}
          alt="Logo Sem Texto do Farol do Porto"
          width={90}
        />
        <img src={FarolDoPortoFont} alt="Farol do Porto" height={75} />
        <p className="text">{textPart1}</p>
        <p className="text">{textPart2}</p>
        <p className="text">{textPart3}</p>
        <p className="text">{textPart4}</p>
      </Container>
      <Container className="bannerImage">
        <div className="overlay" />
      </Container>
    </>
  );
};

export default InfoBanner;
