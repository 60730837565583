import React, { useState } from "react";

import { Map, Marker, TileLayer } from "react-leaflet";

import ComoChegarTitle from "../../assets/img/comoChegar_topo.png";
import { ReactComponent as LeftWave } from "../../assets/svg/leftWave.svg";

import BgButton from "../../assets/svg/map_button.svg";
import ContactDrawer from '../contact/index'

const center = {
  lat: -8.5039926,
  lng: -35.0060494,
};

const zoom = 17;

const ComoChegar = (props) => {

  const [contactDrawerState, setContactDrawerState] = useState(false);

  const contactDrawerClose = () => setContactDrawerState(false);

  const handleOnClickContact = () => {
    setContactDrawerState(true);
  };

  const handleClickRotas = () => {
    window.open(
      'https://www.google.com/maps/dir//Pousada+Farol+do+Porto+-+R.+da+Assembl%C3%A9ia,+109+-+Porto+de+Galinhas,+Ipojuca+-+PE,+55592-000',
      '_bank'
    )
  }

  const handleClickMaps = () => {
    window.open(
      'https://www.google.com/maps/place/Pousada+Farol+do+Porto/@-8.5039873,-35.0082383,17z/data=!3m1!4b1!4m8!3m7!1s0x7aa92c883b87b11:0xed9e70214c345e59!5m2!4m1!1i2!8m2!3d-8.5039926!4d-35.0060496',
      '_blank'
    )
  }
  const {desktop} = props;
  return (
    <div id="section__comochegar" className="comoChegar__container">
      <ContactDrawer 
        desktop={desktop}
        closeDrawer={contactDrawerClose}
        drawerState={contactDrawerState}
      />
      <div className="comoChegar__floatRigth">
        <LeftWave width={150} className="wave" />
      </div>
      <div className="comoChegar__title">
        <img alt="Imagem com o texto: Como Chegar"src={ComoChegarTitle}></img>
        <p>
          Veja como chegar a nossa pousada
        </p>
      </div>
      <div className="comoChegar__mapsContainer">
        <div className="comoChegar__rotas" style={desktop && {width: '60%'}}>
          <div className="comoChegar__rotas__address">
            <p>Endereço</p>
            <p>Av da Assembleia, 109 Porto de Galinhas, Ipojuca, PE Brasil</p>
          </div>
          <div className="comoChegar__rotas__buttonContainer">
            {desktop && 
              <button
                onClick={handleClickMaps}
                className="comoChegar__rotas__buttonMaps waves-effect">Google Maps</button>
            } 
            <button
              onClick={handleClickRotas}
              className={`comoChegar__rotas__button waves-effect ${!desktop && "uppercase"}`}>Rotas</button>
          </div>
        </div>
        <div className="comoChegar__contato">
          <p>Com alguma dúvida, tem alguma reclamação ou sugestão?</p>
          <button onClick={handleOnClickContact} className="waves-effect" style={{ backgroundImage: `url(${BgButton})` }}>
            ENTRE EM CONTATO
          </button>
        </div>
        <Map center={center} zoom={zoom}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
            <Marker
              className="comoChegar__marker"
              position={[center.lat, center.lng]}
            />
        </Map>
      </div>
    </div>
  );
};

export default ComoChegar;
