import React, { useState } from "react";

import Drawer from "@material-ui/core/Drawer";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputBase from "@material-ui/core/InputBase";

import ContactTopoImage from "../../assets/img/contact_topo.png";
import ContactTopSVG from "../../assets/svg/contact_top.svg";
import { ReactComponent as ContactWaveSVG } from "../../assets/svg/contact_wave.svg";

import { ReactComponent as IconWhatsapp } from "../../assets/svg/iconWhatsappSecondColor.svg";
import { ReactComponent as IconInstagram } from "../../assets/svg/iconInstagramSecondColor.svg";
import { ReactComponent as IconFacebook } from "../../assets/svg/iconFacebookSecondColor.svg";

const ContactDrawer = (props) => {
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [phone, setPhone] = useState("");
  let [choice, setChoice] = useState("Assunto");
  let [msg, setMsg] = useState("");

  const handleSubmitEvent = (e) => {
    let mailTo = `mailto:clara@gmail.com?subject=${choice}&body=Telefone p/contato:${phone} \n${msg}`
    let aTag = window.document.createElement("a")
    aTag.setAttribute('href', mailTo);
    aTag.click();
    e.preventDefault();
  };

  const goToWhatsapp = () =>
    window.open("https://api.whatsapp.com/send?l=pt_BR&phone=558196346282");

  const goToFacebook = () =>
    window.open("https://www.facebook.com/faroldoportopousada/");

  const goToInstagram = () =>
    window.open("https://www.instagram.com/faroldoportopousada/");

  return (
    <Drawer anchor="right" open={props.drawerState}>
      <div
        className={props.desktop ? "drawerContainerDesktop" : "drawerContainer"}
        style={{ backgroundImage: `url(${ContactTopSVG})` }}
      >
        <div className="topContainer">
          <p className="closeDrawer" onClick={props.closeDrawer}>
            FECHAR X
          </p>
          <img alt="Imagem com o texto: Contato" src={ContactTopoImage}></img>
          <ContactWaveSVG />
        </div>
        <form onSubmit={handleSubmitEvent}>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nome"
          ></input>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="E-mail"
          ></input>
          <input
            type="number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Telefone"
          ></input>
          <Select
            value={choice}
            input={
              <InputBase
                classes={{
                  root: "customized-select",
                }}
              />
            }
            onChange={(e) => setChoice(e.target.value)}
          >
            <MenuItem value={"Assunto"}>Assunto</MenuItem>
            <MenuItem value={"Preço"}>Preço</MenuItem>
            <MenuItem value={"Reservas"}>Reservas</MenuItem>
            <MenuItem value={"Ajuda"}>Ajuda</MenuItem>
          </Select>
          <textarea
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
            placeholder="Sua mensagem"
            rows="4"
            cols="50"
          />
          <input
            className="submitButton waves-effect"
            type="submit"
            value="Enviar mensagem"
          ></input>
        </form>
        <div>
          <IconWhatsapp onClick={goToWhatsapp} className="iconBottom" />
          <IconFacebook onClick={goToFacebook} className="iconBottom" />
          <IconInstagram onClick={goToInstagram} className="iconBottom" />
        </div>
      </div>
    </Drawer>
  );
};

export default ContactDrawer;
