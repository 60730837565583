import React, { useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import ToolBar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import ContactDrawer from "../contact";
import Drawer from "@material-ui/core/Drawer";
import Container from "@material-ui/core/Container";

import { useHistory } from "react-router-dom";

import Logo from "../../assets/img/faroldoporto.png";
import LogoDesktopInactive from "../../assets/img/logo_footer.png";
import DrawerSvg from "../../assets/svg/drawerBottom.svg";
import { ReactComponent as MenuIcon } from "../../assets/svg/menuIcon.svg";
import { ReactComponent as IconWhatsapp } from "../../assets/svg/iconWhatsapp.svg";
import { ReactComponent as IconInstagram } from "../../assets/svg/iconInstagram.svg";
import { ReactComponent as IconFacebook } from "../../assets/svg/iconFacebook.svg";

import { scroller } from "react-scroll";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    position: "relative",
    zIndex: 100,
  },
  menuButton: {
    position: "absolute",
    left: "90%",
    justifySelf: "flex-end",
    marginRight: theme.spacing(2),
    "&:focus": {
      backgroundColor: "white"
    }
  },
  logoContainer: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    paddingLeft: "0px",
    alignItems: "center",
  },
  logoContainerDesktop:{
    flexGrow: 1,
    display: "flex",
    justifyContent: "left",
    paddingLeft: "20px",
    alignItems: "center",
  },
  logo: {
    height: "70px",
    width: "auto",
  },
  toolbar: {
    minHeight: 45,
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    boxShadow: "0px 3px 6px #00000014",
  },
  toolbarInital: {
    minHeight: 45,
    alignItems: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    boxShadow: "0px 0px 0px ",
  },
  drawer: {
    width: "80vw",
    height: "100%",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
    backgroundImage: `url(${DrawerSvg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    backgroundPosition: "right bottom",
  },
  drawerOptions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    paddingRight: "16px",
  },
  closeDrawer: {
    marginTop: "35px",
    paddingRight: "16px",
    fontFamily: "Barlow",
    letterSpacing: "3px",
    color: "#7C7C7C",
    cursor: "pointer",
  },
  headerItem:{
    marginRight: "20px",
    fontFamily: "Barlow",
    textTransform: "capitalize",
    fontSize: "1em",
    letterSpacing: "1.84px",
    cursor: "pointer",
    color: "white",
  },
  headerItemTriggered:{
    marginRight: "20px",
    fontFamily: "Barlow",
    textTransform: "capitalize",
    fontSize: "1em",
    letterSpacing: "1.84px",
    cursor: "pointer",
    color: "#7C7C7C",
  },
  buttonHeader:{
    backgroundColor: "white",
    color: "#7FBCC2",
    height: "35px",
    width: "100px",
    borderWidth: "0px",
    letterSpacing: "0px",
    borderRadius: "9999px",
    "&:focus": {
      backgroundColor: "white"
    }
  },
  buttonHeaderTrigger:{
    color: "white",
    backgroundColor: "#01a9af",
    "&:focus": {
      backgroundColor: "#01a9af"
    }
  },
  drawerItem: {
    marginBottom: "20px",
    fontFamily: "Barlow",
    textTransform: "uppercase",
    fontSize: "1em",
    letterSpacing: "1.84px",
    cursor: "pointer",
    color: "#000",
  },
  drawerBottom: {
    marginBottom: "35px",
    paddingRight: "16px",
  },
}));

const items = [
  {
    title: "Início",
    section: "section__banner",
  },
  {
    title: "A Pousada",
    section: "section__infoBanner",
  },
  {
    title: "Serviços",
    section: "section__structure",
  },
  {
    title: "Galeria",
    section: "section__galery",
  },
  {
    title: "Depoimentos",
    section: "section__depoimentos",
  },
  {
    title: "Localização",
    section: "section__comochegar",
  },
  {
    title: "Contato",
  },
];

const Header = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  });

  const [drawerState, setDrawerState] = useState(false);
  const [contactDrawerState, setContactDrawerState] = useState(false);

  const openDrawer = () => {
    setDrawerState(true);
  };

  const closeDrawer = () => {
    setDrawerState(false);
  };
  const contactDrawerClose = () => setContactDrawerState(false);

  const handleOnClickButtonReserva = () => {
    window.open('https://reservasonline.lexsis.com.br/ReservasHTML5-1.0.0/?idh=1057', "_blank")
  }

  const handleOnClickContact = () => {
    setContactDrawerState(true);
  };

  const renderItems = () =>
    items.map((item) => {
      let handler = () => {};
      switch (item.title) {
        case "Contato":
          handler = handleOnClickContact;
          break;
        default:
          handler = ((id) => () => scrollTo(id))(item.section);
      }
      return (
        <p key={item.title} onClick={handler} className={desktop ? (trigger ? classes.headerItemTriggered : classes.headerItem) : classes.drawerItem}>
          {item.title}
        </p>
      );
    });

  const scrollTo = (id) => {
    scroller.scrollTo(id, {
      offset: -90,
      duration: 1500,
      delay: 100,
      smooth: true,
    });
    setDrawerState(false);
  };

  const goToHome = () => history.push("/");

  const goToWhatsapp = () =>
    window.open("https://api.whatsapp.com/send?l=pt_BR&phone=558196346282");

  const goToFacebook = () =>
    window.open("https://www.facebook.com/faroldoportopousada/");

  const goToInstagram = () =>
    window.open("https://www.instagram.com/faroldoportopousada/");
  const {desktop} = props;
  return (
    <>
      <AppBar position="fixed" color={desktop && !trigger ? 'transparent': 'white'}>
        <ToolBar className={desktop && !trigger ? classes.toolbarInital : classes.toolbar}>
          <div className={desktop ? classes.logoContainerDesktop : classes.logoContainer} onClick={goToHome}>
            <img alt="Logo do Farol do Porto" className={classes.logo} src={desktop ? (trigger ? Logo : LogoDesktopInactive) : Logo}></img>
          </div>
          { desktop && 
            renderItems()
          }
          {
            desktop &&
            <button 
              onClick={handleOnClickButtonReserva}
              className={`waves-effect ${classes.headerItem} ${classes.buttonHeader} ${trigger && classes.buttonHeaderTrigger }`}>
              Reserva
            </button>
          }
          {!desktop && 
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={openDrawer}
            >
              <MenuIcon className="header__menuIcon" />
            </IconButton>
          }

        </ToolBar>
      </AppBar>

      <Drawer anchor="right" open={drawerState}>
        <Container className={classes.drawer}>
          <p className={classes.closeDrawer} onClick={closeDrawer}>
            FECHAR X
          </p>
          <div className={classes.drawerOptions}>{renderItems()}</div>
          <div className={classes.drawerBottom}>
            <IconWhatsapp
              onClick={goToWhatsapp}
              className="header__drawerBottomItem"
              width={35}
              height={35}
            />
            <IconFacebook
              onClick={goToFacebook}
              className="header__drawerBottomItem"
              width={35}
              height={35}
            />
            <IconInstagram
              onClick={goToInstagram}
              className="header__drawerBottomItem"
              width={35}
              height={35}
            />
          </div>
        </Container>
      </Drawer>
      <ContactDrawer
        desktop={desktop}
        closeDrawer={contactDrawerClose}
        drawerState={contactDrawerState}
      />
    </>
  );
};

export default Header;
