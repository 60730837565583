import React from "react";

import Header from "../components/header";
import Banner from "../components/banner";
import InfoBanner from "../components/infoBanner";
import StructureAndServices from "../components/structuresAndServices";
import TelaDepoimentos from "..//components/depoimentos";
import ComoChegar from "../components/comoChegar";
import TelaGaleria from "../components/galeria";
import Footer from "../components/footer";
import FooterDesk from '../components/footer/footer_web'
import SocialMediaDesktop from "../components/socialMediaDesktop";
import BannerDesktop from "../components/bannerDesktop";
import { useMediaQuery } from "react-responsive";

const HomeScreen = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  return (
    <>
      {isDesktopOrLaptop && (
        <>
          <Header desktop={true}/>
          <BannerDesktop />
          <StructureAndServices desktop={true}/>
          <TelaGaleria web={true}/>
          <TelaDepoimentos web={true}/>
          <ComoChegar desktop={true}/>
          <SocialMediaDesktop/>
          <FooterDesk/>
        </>
      )}
      {isTabletOrMobile && (
        <>
          <Header/>
          <Banner />
          <InfoBanner />
          <StructureAndServices desktop={false}/>
          <TelaGaleria />
          <TelaDepoimentos />
          <ComoChegar/>
          <Footer />
        </>
      )}
    </>
  );
};

export default HomeScreen;
