import React from "react";

import Container from "@material-ui/core/Container";

import BannerLogo from "../../assets/img/bannerLogo.png";

import { ReactComponent as LeftWave } from "../../assets/svg/leftWave.svg";
import { ReactComponent as RightWave } from "../../assets/svg/rightWave.svg";
import { ReactComponent as MouseCall } from "../../assets/svg/mouseCall.svg";

import "./Banner.css";
const textPart1 = "Sua pousada em";
const textPart2 = "Porto de Galinhas";
const textScroll = "Conheça nossa pousada";
const bookingText = "Faça já sua reserva";

const Banner = () => {
  return (
    <>
      <Container id="section__banner" className="container">
        <div className="root">
          <div className="overlay" />
          <div className="rightBall" />
          <div className="leftBall" />
          <div className="leftWaveContainer">
            <LeftWave width={150} className="wave" fill="transparent" />
          </div>
          <div className="tittle">
            <img alt="Logo do Farol do Porto" src={BannerLogo} className="bannerLogo" />
            <div className="subTitle">
              <p className="subTitle1">{textPart1}</p>
              <p className="subTitle2">{textPart2}</p>
            </div>
          </div>
          <div className="rightWaveContainer">
            <RightWave width={150} className="wave" />
          </div>
          <div className="callToScroll">
            <MouseCall height={55} />
            <p className="callToScrollText">{textScroll}</p>
          </div>
        </div>
        <div className="callToBookingWrap">
          <a className="callToBooking waves-effect" rel="noopener noreferrer" href="https://reservasonline.lexsis.com.br/ReservasHTML5-1.0.0/?idh=1057" target="_blank">
            <p>{bookingText}</p>
          </a>
        </div>
      </Container>
    </>
  );
};

export default Banner;
